import React from "react";
import { ShowPart } from "../Page";

interface Props {
  readonly product: ShowPart;
}

export const ImageGallery: React.FC<Props> = ({ product }) => (
  <div className="product-viewer-v2__header-image-container">
    {product.certification_logos && (
      <div className="product-viewer-v2__header-certification-logos">
        {product.certification_logos.map((logo, index) => (
          <img
            key={index}
            className="product-viewer-v2__header-certification-logo"
            src={logo.image_url}
            alt={logo.name}
          />
        ))}
      </div>
    )}
    {product.additional_image && (
      <img
        className="product-viewer-v2__header-additional-image"
        src={product.additional_image.path}
        alt={product.additional_image.className}
      />
    )}
    <img
      className="product-viewer-v2__header-image"
      src={product.image_path}
      alt={product.name}
    />
  </div>
);
