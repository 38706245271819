import { useState } from "react"
import { QueryClient, useQuery } from "@tanstack/react-query";

const queryClient = new QueryClient({});

export type ErrorMetadata = {
    errors: {
        title: string,
        message: string
    }[]
}

export type PaginationMetadata = {
    metadata: {
        currentPage: number;
        limitValue: number;
        totalCount: number;
        totalPages: number;
    }
}

export const useQueryPaginationGQL = <T, K extends keyof T>(
    K: K & T[K] extends PaginationMetadata & ErrorMetadata ? K : never,
    keyName: string,
    params: any[],
    defaultPage: number = 1, 
    defaultPerPage:number = 10 , 
    request: (page: number, perPage: number, ...args: any) => Promise<T>,
    enabled?: boolean | (() => boolean)
) => {

    const [page, setPage] = useState<number>(defaultPage);
    const [perPage, setPerPage] = useState<number>(defaultPerPage);

    const result = useQuery<T>({
        queryKey: [keyName, ...params, page, perPage],
        queryFn: async () => {
            return request(page, perPage, ...params)
        },
        enabled: typeof enabled === 'boolean' ? enabled : enabled? enabled() : true
    }, queryClient);

    const moveToPage = (page: number) => {
        if(page <= 0){
            return;
        }
        if(page > ((result?.data?.[K] as PaginationMetadata)?.metadata?.totalPages ?? 1)) {
            setPage((result?.data?.[K] as PaginationMetadata)?.metadata?.totalPages ?? 1)
        }else{
            setPage(page);
        }
    }

    const changePerPage = (perPage: number) => {
        if(perPage < 0){
            return // do nothing
        }
        setPerPage(perPage)
    }

    const pagePlusOne = () => {
        setPage(page + 1);
    }

    const pageMinusOne = () => {
        setPage(page - 1);
    }

    return {
        result,
        moveToPage,
        changePerPage,
        pagePlusOne,
        pageMinusOne,
        page, //current page
        perPage,
        lastPage: (result?.data?.[K] as PaginationMetadata)?.metadata?.totalPages ?? 0
    };
}