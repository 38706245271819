import {
  AtomicBreadbox,
  AtomicDidYouMean,
  AtomicFacetManager,
  AtomicLayoutSection,
  AtomicLoadMoreResults,
  AtomicNoResults,
  AtomicQueryError,
  AtomicQuerySummary,
  AtomicRefineToggle,
  AtomicSearchBox,
  AtomicSearchInterface,
  AtomicSearchLayout,
  AtomicSortDropdown,
  AtomicSortExpression,
  buildSearchEngine,
  AtomicResultSectionVisual,
  AtomicResultImage,
  AtomicResultSectionTitle,
  AtomicResultLink,
  AtomicResultNumber,
  AtomicResultList,
  AtomicResultSectionExcerpt,
  AtomicCategoryFacet,
  Result,
  AtomicNumericFacet,
  AtomicFormatCurrency,
  AtomicSearchBoxRecentQueries,
} from "@coveo/atomic-react";
import React from "react";
import httpClient from "../../../../api/httpClient";
import I18n from "../../../../helpers/I18n";
import { leadTimeText } from "../../../../helpers/LeadTimeHelper";

interface PageProps {
  accessToken: string,
  organizationId: string,
  organizationEndpoints: { platform: string, analytics: string, search: string},
  locale: "en" | "fr",
  region: "us" | "eu" | "ca" | "intl",
  partCategoryNameByCode: Record<string, string>,
  shipmentSiteKey: number
}

interface PartResult extends Result {
  raw: Result["raw"] & {
    part_number: string,
    local_price: number,
    shipment_delays: number,
    part_status: string
  }
}

const currencyCodeByRegion = {
  "us": "USD",
  "eu": "EUR",
  "ca": "CAD",
  "intl": "USD"
}

const currencySymbolByRegion = {
  "us": "$",
  "eu": "€",
  "ca": "$",
  "intl": "$"
}

const renderResult = (result: PartResult, props: PageProps): JSX.Element => {
  return <>
    {/*
      This element is rendered from a template, and so its shadow DOM isn't accessible by global CSS. Sometimes these
      templates that Coveo uses have `part` attributes that can be used to target the shadow DOM elements, but not in
      this case.
    */}

    <style>{`
      .stat-box-section {
        display: flex;
      }

      .stat-box {
        display: flex;
        align-items: center;
      }

      .stat-box:not(:last-child) {
        margin-right: 8px;
      }

      atomic-result-section-visual {
        padding: 20px;
        background-color: #f8fafc;
      }

      atomic-result-image img {
        background-color: #ffffff;
        mix-blend-mode: multiply;
      }

      atomic-result-section-title {
        padding: 0 20px;
        min-height: 54px;
      }

      atomic-result-section-excerpt {
        padding: 0 20px 20px 20px;
        max-height: unset !important;
      }

      atomic-result-link a:hover {
        text-decoration: none !important;
        color: var(--atomic-on-background) !important;
      }

      atomic-result-link a:visited {
        color: var(--atomic-on-background) !important;
      }
    `}</style>
    <AtomicResultSectionVisual>
      <AtomicResultImage field="ec_images" fallback="https://assets.vention.io/png/generic_machine.png"/>
    </AtomicResultSectionVisual>

    <AtomicResultSectionTitle>
      <AtomicResultLink />
    </AtomicResultSectionTitle>

    <AtomicResultSectionExcerpt>
      <div className="stat-box-section">
        <div className="stat-box">
          <span>{result.raw.part_number}</span>
        </div>
      </div>

      <div>
        <span>{currencySymbolByRegion[props.region]}</span>
        <AtomicResultNumber field="local_price" />
        <span> {currencyCodeByRegion[props.region]}</span>
      </div>

      <div className="stat-box-section">
        <div className="stat-box">
          <span style={{color: ["in_stock", "deplete"].includes(result.raw.part_status) ? "#1ac876" : "#197ce0"}}>{
            leadTimeText(
              result.raw.shipment_delays,
              result.raw.part_status
            )
          }</span>
        </div>
      </div>
    </AtomicResultSectionExcerpt>
  </>
}

const Page: React.FC<PageProps> = (props) => {
  const engine = buildSearchEngine({
    configuration: {
      search: { pipeline: "Parts" },
      accessToken: props.accessToken,
      organizationId: props.organizationId,
      organizationEndpoints: props.organizationEndpoints,
      renewAccessToken() {
        return httpClient({
          url: "/graphql",
          method: "post",
          data: JSON.stringify({
            query: "mutation { coveoJwtCreate(input: {}) { jwt } }"
          })
        }).then(response => response.data.data.coveoJwtCreate.jwt)
      },
      preprocessRequest: (request, _clientOrigin, metadata) => {
        if (metadata?.method === "search" && request.body) {
          const body = JSON.parse(request.body.toString());

          body.dictionaryFieldContext = {local_price: props.region, shipment_delays: props.shipmentSiteKey.toString()};
          body.fieldsToInclude.push("local_price");
          body.fieldsToInclude.push("shipment_delays");
          body.fieldsToInclude.push("part_status");
          body.fieldsToInclude.push("part_number");

          body.numberOfResults = 20;

          request.body = JSON.stringify(body);
        }

        return request;
      }
    }
  });

  return (
    <AtomicSearchInterface
      languageAssetsPath="https://ventionblobs.s3.us-east-1.amazonaws.com/coveo-atomic-assets/lang"
      iconAssetsPath="https://ventionblobs.s3.us-east-1.amazonaws.com/coveo-atomic-assets/assets"
      engine={engine}
      localization-compatibility-version="v4"
      language={props.locale}
      localization={(i18n) => {
        i18n.addResourceBundle("en", "caption-ec_category", props.partCategoryNameByCode);
      }}
    >
      <AtomicSearchLayout>
        <div className="atomic-header-section" style={{gridArea: "header"}}>
          <h2>{I18n.t("views.parts_library.header")}</h2>
        </div>
        <AtomicLayoutSection section="search">
          <AtomicSearchBox textarea={true}>
            <AtomicSearchBoxRecentQueries />
            <AtomicDidYouMean />
          </AtomicSearchBox>
        </AtomicLayoutSection>
        <AtomicLayoutSection section="facets">
          <AtomicFacetManager>
            <AtomicCategoryFacet
              field="ec_category"
              withSearch
              delimitingCharacter="|"
              label="Category"
              numberOfValues={999} // We want to show all categories. This assumes that there'll never be more than 999 categories.
            />
          </AtomicFacetManager>
          <AtomicFacetManager>
            <AtomicNumericFacet field="local_price" label="Price" withInput="integer" numberOfValues={0}>
              <AtomicFormatCurrency currency={currencyCodeByRegion[props.region]} />
            </AtomicNumericFacet>
          </AtomicFacetManager>
        </AtomicLayoutSection>
        <AtomicLayoutSection section="main">
          <AtomicLayoutSection section="status">
            <AtomicBreadbox />
            <AtomicQuerySummary />
            <AtomicRefineToggle />
            <AtomicSortDropdown>
              <AtomicSortExpression label="relevance" expression="relevancy" />
              <AtomicSortExpression
                label="Price (low to high)"
                expression="local_price ascending"
              />
              <AtomicSortExpression
                label="Price (high to low)"
                expression="local_price descending"
              />
            </AtomicSortDropdown>
          </AtomicLayoutSection>
          <AtomicLayoutSection section="results">
            <AtomicResultList
              display="grid"
              density="normal"
              imageSize="large"
              template={(result) => renderResult(result, props)}
            />
            <AtomicQueryError />
            <AtomicNoResults />
          </AtomicLayoutSection>
          <AtomicLayoutSection section="pagination">
            <AtomicLoadMoreResults />
          </AtomicLayoutSection>
        </AtomicLayoutSection>
      </AtomicSearchLayout>
    </AtomicSearchInterface>
  );
}

export default Page;
