import React from 'react'
import { VSemiBoldText } from '../text/Text';

export type PartSkeletonListProps = {
  readonly count: number;
}
export const PartSkeletonList: React.FC<PartSkeletonListProps> = ({ count }: PartSkeletonListProps) => {
  return (
    <section
      id='part-recommendations-v2'
      className='product-viewer-v2__part-recommendations product-viewer-v2__section'
    >
      <VSemiBoldText fontSize="xl">
        Similar Parts
      </VSemiBoldText>
      <div className='product-viewer-v2__card-links'>
        {Array(count).fill(0).map((_, index) => (
          <a key={index} className="similar-part-card-list" target="_blank">

            <div className="space-margin-bottom-xl space-margin-top-xl background-color-gray-light-200" style={{ height: "1px" }}></div>

            <div className='skeleton skeleton-image-container'></div>

            <div style={{ padding: 10 }}>

              {/* Part Number */}
              <div className="skeleton skeleton-text-16-80"></div>

              {/* Part Name */}
              <div className="skeleton skeleton-text-24-100"></div>

              <div className="space-2"></div>

              {/* Part Price */}
              <div className="skeleton skeleton-text-24-80"></div>

              {/* Part Next Ship */}
              <div className="skeleton skeleton-text-16-100"></div>

              <div className="space-2"></div>

              {/* Add to cart button */}
              <div className="skeleton skeleton-text-16-100"></div>

            </div>
          </a>
        ))}
      </div>
    </section>
  )
}