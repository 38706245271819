import { gql } from 'graphql-request'

export const fetchSimilarPartsQuery = gql`
query fetchSimilarParts($excludePartIds: [ID!], $categoryIds: [ID!], $page: Int, $pageSize: Int) {
  parts(page: $page, pageSize: $pageSize, excludePartIds: $excludePartIds, categoryIds: $categoryIds) {
    collection {
      id
      name
      partNumber
      description
      imagePath
      displayPrice
      fullUrl
      technicalSpecifications
    }
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
    errors {
      title,
      message
    }
  }
}
`