import React, { ReactNode } from "react";
import { VMediumText, VRegularText } from "../text/Text";
import { PartCheckbox } from "./PartCheckbox";
import { PartDTO } from "../../api/Parts/dtos/PartDTO";

export type PartCardV2Props = {
    readonly part: Partial<PartDTO>;
    readonly children: ReactNode;
    readonly isSelectable: boolean
    readonly isSelected: boolean;
    readonly onSelectClicked?: (part: Partial<PartDTO>) => void;
}

export const PartCardV2: React.FC<PartCardV2Props> = (props: PartCardV2Props) => {
    const { part, children, isSelectable, isSelected, onSelectClicked } = props;

    const SelectCheckbox = () => {
      if(!isSelectable) {
        return null;
      }

      return (
        <PartCheckbox<Partial<PartDTO>>
          element={part}
          isSelected={isSelected}
          onSelectClicked={onSelectClicked} />
      )
    }
    return (
        <div className="product-viewer-v2__card-link">
            
            <div className="product-viewer-v2__card-link-image-container product-viewer-v2__card-link">
              <img
                className="product-viewer-v2__card-link-image"
                src={part.imagePath}
                alt={part.name}
                loading="lazy"
              />
            </div>
            <SelectCheckbox/>
            
            <div className="product-viewer-v2__card-link-text">
              <a href={part.fullUrl} className="product-viewer-v2__card-empty-container-link">
                <VRegularText color='terciary' fontSize='lg'>
                  {part?.partNumber ?? ''}
                </VRegularText>

                <VMediumText color="primary" fontSize="lg">
                  {part?.name ?? ''}
                </VMediumText>
              </a>

              <div className="product-viewer-v2__card-link-subtext space-margin-bottom-2xl space-margin-top-2xl">
                {part.displayPrice}
              </div>
              {
                children
              }
            </div>
          </div>
    )
}