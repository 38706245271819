import { gql } from 'graphql-request'

export const fetchFullPartsByIdsQuery = gql`
query fetchFullPartsByIds($includePartIds: [ID!], $page: Int, $pageSize: Int) {
  parts(page: $page, pageSize: $pageSize, includePartIds: $includePartIds) {
    collection {
      id
      name
      description
      partNumber
      displayPrice
      hidePrice
      isSoftware
      weight
      width
      height
      length
      material
      surfaceFinish
      applicableStandards
      ventionComment
      technicalSpecifications
      imagePath
      fullUrl
    }
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
    errors {
      title,
      message
    }
  }
}
`