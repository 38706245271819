import React, { useState } from "react";
import { LocalizedPaths } from "../../../../types/Common";
import I18nProvider from "../../../../components/common/providers/I18nProvider";
import I18n from "../../../../helpers/I18n";
import ContactForm from "../partials/ContactForm";
import SuccessNote from "../partials/SuccessNote";

interface Props {
  localizedPaths: LocalizedPaths;
  captchaKey: string;
  languageCode: string;
}

const Page: React.FC<Props> = ({
  localizedPaths,
  captchaKey,
  languageCode,
}) => {
  const [formSuccess, setFormSuccess] = useState<boolean>(false);

  return (
    <I18nProvider localizedPaths={localizedPaths}>
      <section className="try-before-you-buy">
        <div className="try-before-you-buy_palletizing">
          <div className="try-before-you-buy_info_container">
            <h1 className="section__supertitle">
              {I18n.t(
                "views.try_before_you_buy.palletizing.info_container.supertitle",
              )}
            </h1>
            <h2 className="section__title">
              {I18n.t(
                "views.try_before_you_buy.palletizing.info_container.title",
              )}
            </h2>
            <div className="features__card">
              <p className="section__supertitle">
                {I18n.t(
                  "views.try_before_you_buy.palletizing.feature_card.card_1.supertitle",
                )}
              </p>
              <h3>
                {I18n.t(
                  "views.try_before_you_buy.palletizing.feature_card.card_1.title",
                )}
              </h3>
              <p className="section__description">
                {I18n.t(
                  "views.try_before_you_buy.palletizing.feature_card.card_1.description",
                )}
              </p>
            </div>
            <div className="features__card">
              <p className="section__supertitle">
                {I18n.t(
                  "views.try_before_you_buy.palletizing.feature_card.card_2.supertitle",
                )}
              </p>
              <h3>
                {I18n.t(
                  "views.try_before_you_buy.palletizing.feature_card.card_2.title",
                )}
              </h3>
              <p className="section__description">
                {I18n.t(
                  "views.try_before_you_buy.palletizing.feature_card.card_2.description",
                )}
              </p>
            </div>
            <div className="highlights_container">
              <span className="highlights_container_icon">
                <i className="ti ti-checkbox"></i>
              </span>
              <p className="highlights_container_text">
                {I18n.t(
                  "views.try_before_you_buy.palletizing.highlights_container.highlight_1",
                )}
              </p>
            </div>

            <div className="highlights_container">
              <span className="highlights_container_icon">
                <i className="ti ti-arrow-autofit-up"></i>
              </span>
              <p className="highlights_container_text">
                {I18n.t(
                  "views.try_before_you_buy.palletizing.highlights_container.highlight_2",
                )}
              </p>
            </div>

            <div className="highlights_container">
              <span className="highlights_container_icon">
                <i className="ti ti-square-x"></i>
              </span>
              <p className="highlights_container_text">
                {I18n.t(
                  "views.try_before_you_buy.palletizing.highlights_container.highlight_3",
                )}
              </p>
            </div>

            <a href="#try_before_you_buy_register" className="leading_link">
              {I18n.t(
                "views.try_before_you_buy.palletizing.leading_link.link_text",
              )}
              <span className="leading_link_icon">
                <i className="ti ti-arrow-down"></i>
              </span>
            </a>
          </div>
          <div className="img_container">
            <img
              src="https://assets.vention.io/page-assets/try-before-you-buy/rapid-series-palletizer-ur20-telescopic-column.png"
              alt="rapid series palletizer ur20 telescopic column"
            />
          </div>
        </div>
        <div
          className="try-before-you-buy_palletizing_registration"
          id="try_before_you_buy_register"
        >
          <div className="try-before-you-buy_palletizing_registration_section">
            <div className="description__container">
              <h2 className="section__supertitle">
                {I18n.t(
                  "views.try_before_you_buy.palletizing.registration.description_container.supertitle",
                )}
              </h2>
              <h3 className="section__title">
                {I18n.t(
                  "views.try_before_you_buy.palletizing.registration.description_container.title",
                )}
              </h3>
              <div className="registration_highlights">
                <h4 className="registration_highlights__title">
                  {I18n.t(
                    "views.try_before_you_buy.palletizing.registration.highlights_container.lists.list_1.title",
                  )}
                </h4>
                <ul className="registration_highlights__list">
                  {I18n.t(
                    "views.try_before_you_buy.palletizing.registration.highlights_container.lists.list_1.description",
                  ).map((item: string) => (
                    <li> {item} </li>
                  ))}
                </ul>
              </div>
              <div className="registration_highlights">
                <h4 className="registration_highlights__title">
                  {I18n.t(
                    "views.try_before_you_buy.palletizing.registration.highlights_container.lists.list_2.title",
                  )}
                </h4>
                <ul className="registration_highlights__list">
                  {I18n.t(
                    "views.try_before_you_buy.palletizing.registration.highlights_container.lists.list_2.description",
                  ).map((item: string) => ( <li> {item} </li>
                  ))}
                </ul>
              </div>
              <div className="registration_highlights">
                <h4 className="registration_highlights__title">
                  {I18n.t(
                      "views.try_before_you_buy.palletizing.registration.highlights_container.lists.list_3.title",
                    )}
                </h4>
                <ul className="registration_highlights__list">    
                  {I18n.t(
                    "views.try_before_you_buy.palletizing.registration.highlights_container.lists.list_3.description",
                  ).map((item: string) => ( <li> {item} </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="form__container">
              {formSuccess ? (
                <SuccessNote />
              ) : (
                <ContactForm
                  setFormSuccess={setFormSuccess}
                  captchaKey={captchaKey}
                  languageCode={languageCode}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </I18nProvider>
  );
};

export default Page;
