import React from "react";
import { stringToKebabCase } from "../../../../../helpers/StringHelper";
import { ShowPart } from "../Page";
import { downloadPartStepFile } from "../../../../../helpers/DownloadHelper";
import ContactCta from "./ContactCta";
import Table from "../../../../../components/vention_ui/shared_components/Table";

interface Props {
  part: ShowPart;
}

const TechnicalDocuments: React.FC<Props> = ({ part }) => {
  const technicalDrawingRows: React.ReactNode[][] = [];

  if (!part.hide_drawing_2d_file && part.pdf_availability) {
    technicalDrawingRows.push([
      "2D Model",
      <a
        href={`/parts/${part.id}/download_pdf`}
        className="product-viewer-v2__text-link"
        data-analytic-id="part-page-2d-drawing-link"
      >
        Download
      </a>,
    ]);
  }

  if (part.step_availability) {
    technicalDrawingRows.push([
      "3D Model",
      <button
        className="product-viewer-v2__text-link"
        onClick={() => downloadPartStepFile(part.id)}
      >
        Download
      </button>,
    ]);
  }

  const technicalDocumentRows: React.ReactNode[][] =
    part.published_technical_documents.map((technicalDocument) => [
      technicalDocument.title,
      <a
        className="product-viewer-v2__text-link"
        href={`/resources/guides/${technicalDocument.url}-${technicalDocument.id}`}
        target="_blank"
        data-analytic-id={`part-page-${stringToKebabCase(technicalDocument.title)}-link`}
      >
        View
      </a>,
    ]);

  const legacyTechnicalDocumentRows: React.ReactNode[][] =
    part.legacy_technical_documents.map((technicalDocument) => [
      technicalDocument.name,
      <a
        className="product-viewer-v2__text-link"
        href={technicalDocument.path}
        target="_blank"
        data-analytic-id={`part-page-${stringToKebabCase(technicalDocument.name)}-link`}
      >
        View
      </a>,
    ]);

  const partDocuments = part.part_documents.map((partDocument) => [
    partDocument.title,
    <a
      className="product-viewer-v2__text-link"
      href={partDocument.file_url}
      target="_blank"
      data-analytic-id={`part-page-${stringToKebabCase(partDocument.title)}-link`}
    >
      View
    </a>,
  ]);

  const partCalculators = part.calculators.map((calculator) => [
    calculator.name,
    <a
      className="product-viewer-v2__text-link"
      href={calculator.url}
      target="_blank"
      data-analytic-id={calculator.analytic_id}
    >
      View
    </a>,
  ]);

  return (
    <section
      id="technical-documents"
      className="product-viewer-v2__technical-documents product-viewer-v2__section"
    >
      {technicalDrawingRows.length > 0 && (
        <Table
          headers={["Technical Drawing"]}
          headersRowClassName="screen-reader-text"
          data={[["Technical Drawing", ""], ...technicalDrawingRows]}
        ></Table>
      )}

      {technicalDocumentRows.length > 0 && (
        <Table
          headers={["Technical Documentation"]}
          headersRowClassName="screen-reader-text"
          data={[
            ["Technical Documentation", ""],
            ...technicalDocumentRows,
            ...legacyTechnicalDocumentRows,
            ...partDocuments,
            ...partCalculators,
          ]}
        />
      )}

      <ContactCta />
    </section>
  );
};

export default TechnicalDocuments;
