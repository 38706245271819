import React from "react";
import { GeneralSpecification, PartDTO } from "../../../../../api/Parts/dtos/PartDTO";
import { TechnicalSpecificationPropertiesDTO } from "../../../../../api/Parts/dtos/TechnicalSpecificationDTO";
import { VBoldText, VRegularText, VSemiBoldText } from "../../../../../components/text/Text";
import Button from "../../../../../components/common/buttons/Button";
import { splitAndCapitalize } from "../../../../../helpers/StringHelper";

const GENERAL_SPECIFICATION_PROPERTY_NAME: (keyof GeneralSpecification)[] = ['partNumber', 'name', 'weight', 'width', 'height', 'displayPrice', 'material'] as const;

export type CompareModalProps = {
    mainPart: Partial<PartDTO>;
    selectedPartsToCompare: Partial<PartDTO>[];
    fullPartsToCompare?: PartDTO[];
    isOpen: boolean;
    allPropertyLabels?: Record<string, string[]>;
    isLoadingAddingPartToCart: { [key: string]: boolean };

    onClose: () => void;
    onAddPartToCart: (part: string) => void;
}

export const CompareModal: React.FC<CompareModalProps> = (props: CompareModalProps) => {

    const { isOpen, selectedPartsToCompare, mainPart, fullPartsToCompare, allPropertyLabels, isLoadingAddingPartToCart, onClose, onAddPartToCart } = props;

    const getProperty = (property: string, listOfProperties: TechnicalSpecificationPropertiesDTO[]): TechnicalSpecificationPropertiesDTO => {
        return listOfProperties.find(prop => prop.label === property) ?? { label: property, spec_name: '', value: '', unit: '' };
    }

    const handleOutClick = (event: React.MouseEvent<HTMLElement>) => {
        event?.stopPropagation();
        if (event?.target?.["className"] === "compare-part-modal") {
            onClose()
        }
    }

    const TechnicalSpecificationComponent = (props: { label: string, propertyLabels: string[], partResult: PartDTO }) => {

        const foundSpec = props.partResult.technicalSpecifications.find(technicalSpec => technicalSpec.label === props.label);

        return (
            <>
                <table className="compare-part-modal__table compare-part-modal__table__table" >
                    <tr className="compare-part-modal__table">
                        <th className="compare-part-modal__table compare-part-modal__table__header" >
                            <VBoldText> { props.label }</VBoldText>
                        </th>
                    </tr>
                    <tbody>
                        {
                            props.propertyLabels?.map((label) => {
                                const property = getProperty(label, foundSpec?.properties ?? [])
                                return (
                                    <tr key={property.label} className="compare-part-modal__table  compare-part-modal__table__element">
                                        <td className="compare-part-modal__table">
                                            <VSemiBoldText>{splitAndCapitalize(property.label)}</VSemiBoldText>
                                            <VRegularText>{!property.value ? 'N/A\n\t' : (property.value.length < 20 ? `${property.value}\n\t` : property.value)}</VRegularText>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </>
        )
    }

    if (!isOpen) {
        return null;
    }

    return (
        <div className="compare-part-modal" onClick={handleOutClick}>

            <div className="compare-part-modal__container">
                <div className="compare-part-modal__header">
                    <VBoldText>{"Parts "}{`(${selectedPartsToCompare.length})`}</VBoldText>
                    <button className="button-blank" style={{ position: "relative", right: "20px" }} onClick={onClose}>
                        <span aria-hidden='true' className='icon-close'></span>
                    </button>
                </div>
                <div className="compare-part-modal__compare-container">
                    {
                        fullPartsToCompare?.map((partResult: PartDTO) => {
                            return (
                                <div key={partResult.id}
                                    className="compare-part-modal__part_card"
                                    aria-selected={`${mainPart.id}` === partResult.id}>
                                    <div className="compare-part-modal__image-container">
                                        <img
                                            className="product-viewer-v2__card-link-image"
                                            src={partResult.imagePath}
                                            alt={partResult.name}
                                            loading="lazy"
                                        />
                                    </div>
                                    {
                                        `${mainPart.id}` === partResult.id && (<div className="chip-pinned">Pinned</div>)
                                    }
                                    <VRegularText marginTop="md">{partResult.displayPrice}</VRegularText>

                                    <Button
                                        title="Add to Cart"
                                        className="vention-ui-button--primary space-margin-top-md"
                                        isLoading={isLoadingAddingPartToCart?.[partResult.id] === true}
                                        onClick={() => onAddPartToCart(partResult.id)}>
                                        {"Add to Cart"}
                                    </Button>
                                    <table className="compare-part-modal__table compare-part-modal__table__table" >
                                        <tr className="compare-part-modal__table" >
                                            <th className="compare-part-modal__table compare-part-modal__table__header" >
                                                <VBoldText >General Specification</VBoldText>
                                            </th>
                                        </tr>
                                        <tbody>

                                            {
                                                GENERAL_SPECIFICATION_PROPERTY_NAME.map((key, index) => {
                                                    return (
                                                        <tr className="compare-part-modal__table  compare-part-modal__table__element" aria-selected={key === 'name' ? "true" : "false"} key={key}>
                                                            <td className="compare-part-modal__table">
                                                                <VSemiBoldText>{splitAndCapitalize(key)}</VSemiBoldText>
                                                                <VRegularText>{!partResult[key] ? 'N/A\n\t' : (partResult[key].length < 20 ? `${partResult[key]}\n\t` : partResult[key])}</VRegularText>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>


                                    </table>

                                    {
                                        Object.keys(allPropertyLabels ?? {}).map(keyLabel => (
                                        <TechnicalSpecificationComponent key={keyLabel} label={keyLabel} partResult={partResult} propertyLabels={allPropertyLabels?.[keyLabel] ?? []} />
                                    ))
                                    }

                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}
