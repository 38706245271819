import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import Breadcrumbs from "../../../../components/vention_ui/application_components/Breadcrumbs";
import TechnicalDocuments from "./components/TechnicalDocuments";
import Specifications from "./components/Specifications";
import WhatsIncluded from "./components/WhatsIncluded";
import Faqs from "./components/Faqs";
import { Cart } from "./components/Cart";
import { ImageGallery } from "./components/ImageGallery";
import { useQueryPaginationGQL } from "../../../../hooks/usePaginationGQL";
import { PartCollectionDTO, SimilarPartService } from "../../../../api/Parts/PartsService";
import { PartSkeletonList } from "../../../../components/parts/PartSkeletonList";
import SimilarParts from "./components/SimilarPart";
import { addOnePartToCart } from "../../../../api/Cart/Service";
import { QueryClient, useMutation } from "@tanstack/react-query";
import { VRegularText, VSemiBoldText } from "../../../../components/text/Text";
import { SimilarPartDTO } from "../../../../api/Parts/dtos/SimilarPartDTO";
import { useModal } from '../../../../hooks/useModal';
import { CompareGadget } from '../show/components/CompareGadget'
import { PartDTO } from "../../../../api/Parts/dtos/PartDTO";
import { CompareModal } from "./components/CompareModal";
import { uniq } from "lodash";

export interface ShowPart {
  readonly id: number;
  readonly name: string;
  readonly part_number: string;
  readonly description: string;
  readonly is_light_duty: boolean;
  readonly weight?: number;
  readonly length?: number;
  readonly width?: number;
  readonly height?: number;
  readonly hide_price: boolean;
  readonly material: string;
  readonly surface_finish: string;
  readonly applicable_standards: string;
  readonly vention_comment: string;
  readonly is_software: boolean;
  readonly part_category: ShowPartCategory & {
    parent: ShowPartCategory;
  };
  readonly image_path: string;
  readonly certification_logos: ReadonlyArray<ShowCertificationLogo>;
  readonly additional_image: {
    readonly path: string;
    readonly className: string;
  };
  readonly status_availability: ShowPartStatusAvailability;
  readonly display_price: string;
  readonly display_price_raw?: {
    amount: string;
    currency_symbol: string;
    currency_code: string;
  };
  readonly shipment_delay_weeks: number;
  readonly canonical_url: string;
  readonly allow_checkout: boolean;
  readonly pdf_availability: boolean;
  readonly step_availability: boolean;
  readonly hide_drawing_2d_file: boolean;
  readonly published_technical_documents: ReadonlyArray<ShowTechnicalDocument>;
  readonly legacy_technical_documents: ReadonlyArray<ShowLegacyTechnicalDocument>;
  readonly associated_fasteners: ReadonlyArray<ShowFastener>;
  readonly light_duty_fasteners: ReadonlyArray<ShowFastener>;
  readonly part_documents: ReadonlyArray<ShowPartDocument>;
  readonly calculators: ReadonlyArray<ShowCalculator>;
  readonly technical_specifications: ReadonlyArray<ShowTechnicalSpecs>;
}

interface ShowPartStatusAvailability {
  readonly className: string;
  readonly text: string;
}
interface ShowPartCategory {
  readonly name: string;
  readonly url: string;
  readonly id: number;
}

interface ShowTechnicalDocument {
  readonly url: string;
  readonly id: number;
  readonly title: string;
}

interface ShowLegacyTechnicalDocument {
  readonly name: string;
  readonly path: string;
}

interface ShowPartDocument {
  readonly title: string;
  readonly file_url: string;
}

interface ShowCalculator {
  readonly name: string;
  readonly url: string;
  readonly analytic_id: string;
  readonly description: string;
}

interface ShowTechnicalSpecs {
  readonly label: string;
  readonly properties: ReadonlyArray<ShowTechnicalSpecsProperties>;
}

interface ShowTechnicalSpecsProperties {
  readonly label: string;
  readonly unit: string;
  readonly value: string;
  readonly spec_name: string;
}

interface ShowCertificationLogo {
  readonly id: number;
  readonly image_url: string;
  readonly name: string;
}

export interface ShowFastener {
  readonly child_id: number;
  readonly child_repeat_count: number;
  readonly part: {
    readonly part_number: string;
  };
}

export interface ShowPartVariantOption {
  readonly id: number;
  readonly label: string;
  readonly order: number;
  readonly slug: string;
}

export interface ShowIncludedPartOrFastener {
  readonly name: string;
  readonly quantity: number;
  readonly part_url?: string;
  readonly thumbnail_image_path: string;
}

export interface ShowRecommendedPartSearch {
  readonly part_name: string;
  readonly shipment_delay_weeks: number;
  readonly status_availability: ShowPartStatusAvailability;
  readonly display_price: string;
  readonly image_path: string;
  readonly full_url: string;
}

interface Props {
  readonly part: ShowPart;
  readonly partVariantsOptions: ReadonlyArray<ShowPartVariantOption>;
  readonly includedPartsAndFasteners: ReadonlyArray<ShowIncludedPartOrFastener>;
}

const Page: React.FC<Props> = ({
  part,
  partVariantsOptions,
  includedPartsAndFasteners
}) => {

  const [selectedPartsToCompare, setSelectedPartsToCompare] = useState<SimilarPartDTO[]>([]);

  const [allPropertyLabels, setAllPropertiesLabels] = useState<Record<string, string[]>>({});

  const { isOpen: isComparingParts, onToggle: handleToggleComparingParts, onClose: handleCloseComparingParts } = useModal();

  const { isOpen: isShowComparePartsModal, onOpen: handleOpenCompareParts, onToggle: handleToggleComparePartsModal, onClose: handleCloseComparePartsModal } = useModal();

  const resultSimilarParts = useQueryPaginationGQL<PartCollectionDTO<SimilarPartDTO>, 'parts'>('parts', "SimilarParts", [part?.id, part?.part_category?.id], 1, 8, SimilarPartService.querySimilarParts);

  const resutlFullCompareParts = useQueryPaginationGQL<PartCollectionDTO<PartDTO>, 'parts'>('parts', 'FullParts', [[`${part.id}`, ...selectedPartsToCompare.map(part => part.id)]], 1, 4, SimilarPartService.queryFullPartsByIds, selectedPartsToCompare.length > 0 && isShowComparePartsModal)

  const { data: similarParts, isLoading: isLoadingSimilarParts, isError: isErrorSimilarParts } = resultSimilarParts.result;

  const [isLoadingAddPartToCart, setIsLoadingAddPartToCart] = useState<{ [key: string]: boolean }>({});

  const { mutateAsync: addPartToCart } = useMutation({
    mutationFn: async (similarPartId: string) => {
      setIsLoadingAddPartToCart({ ...isLoadingAddPartToCart, [`${similarPartId}`]: true })
      const result = await new Promise((resolve) => {
        addOnePartToCart(
          similarPartId,
          (response: unknown) => {
            resolve(response)
            setIsLoadingAddPartToCart({ ...isLoadingAddPartToCart, [`${similarPartId}`]: false })
          },
        );
      });

      return result
    }
  }, new QueryClient());

  useEffect(() => {
    if ((resutlFullCompareParts.result?.data?.parts?.collection?.length ?? 0) > 0) {
      const propertiesLabels: Record<string, string[]> | undefined =
        resutlFullCompareParts.result.data?.parts.collection?.flatMap(part => part.technicalSpecifications)
          .reduce<Record<string, string[]>>((acc, cur) => {
            console.log(cur)
            if(!acc[cur.label]) {
              acc[cur.label] = cur.properties.map(property => property.label); //create first one
            }else {
              acc[cur.label] = uniq([...acc[cur.label], ...cur.properties.map(property => property.label)]);
            }
            return acc;
          }, {});
      setAllPropertiesLabels(propertiesLabels ?? {});
      console.log(propertiesLabels)
      // Sort API result, no need to create start
      resutlFullCompareParts.result?.data?.parts?.collection?.sort((a, _) => a.id === `${part.id}` ? -1 : 1)
    }

  }, [resutlFullCompareParts?.result?.data?.parts?.collection])

  const buildBreadcrumbs = () => {
    const breadCrumbs = [
      {
        label: "Parts Library",
        link: "/parts-library",
      },
    ];

    const { part_category, canonical_url } = part;

    if (part_category) {
      breadCrumbs.push({
        label: part_category.parent.name,
        link: `/parts-library/${part_category.parent.url}`,
      });
      breadCrumbs.push({
        label: part_category.name,
        link: `/parts-library/${part_category.url}`,
      });
    }

    breadCrumbs.push({
      label: "Product Details",
      link: canonical_url,
    });

    return breadCrumbs;
  };

  const handleAddSimilarPartToCart = async (similarPartId: string) => {
    await addPartToCart(similarPartId);
  }

  const handleNavigateSimilarPartsToNextPage = () => {
    resultSimilarParts.pagePlusOne();
  }

  const handleNavigateSimilarPartsToPreviousPage = () => {
    resultSimilarParts.pageMinusOne();
  }

  const handleOnComparingParts = () => {
    handleToggleComparingParts();
  }

  const handleOnSelectPartToCompare = (part: Partial<PartDTO>) => {
    if (selectedPartsToCompare.includes(part as SimilarPartDTO)) {
      setSelectedPartsToCompare(selectedPartsToCompare.filter(element => element.id !== part.id));
    } else if (selectedPartsToCompare.length >= 3) {
      // show toast 
      toastr.info("Only 4 parts can be selected for comparing")
    } else {
      setSelectedPartsToCompare([...selectedPartsToCompare, part as SimilarPartDTO])
    }
  }

  const handleOnCleanComparingParts = () => {
    setSelectedPartsToCompare([]);
  }

  const handleCompare = () => {
    handleOpenCompareParts()
  }

  // Change skeleton title to similar parts and update size
  // Placeholder for images
  // 

  // Sub component
  const SimilarPartComponent = () => {
    if (isLoadingSimilarParts) {
      return <PartSkeletonList count={8} />;
    }

    if (isErrorSimilarParts || (similarParts?.parts?.errors?.length ?? 0) > 0) {
      return <></>; // nothing to be displayed at the moment when error occur
    }

    if ((similarParts?.parts?.collection?.length ?? 0) === 0) {
      return <></>;
    }

    return (
      <section
        id="part-recommendations"
        className="product-viewer-v2__part-recommendations product-viewer-v2__section"
      >
        <div className="space-margin-bottom-xl space-margin-top-xl" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: '100%', alignItems: 'center' }}>
          <VSemiBoldText fontSize="xl">
            Similar Parts
          </VSemiBoldText>

          <div style={{ display: "flex" }}>
            <VRegularText>Compare parts</VRegularText>
            <input className="space-margin-left-xl space-margin-top-none" style={{ alignSelf: 'center' }} type='checkbox' checked={isComparingParts} onChange={handleOnComparingParts} />
          </div>
        </div>
        <div className="space-margin-bottom-xl background-color-gray-light-200" style={{ height: "1px" }}>

        </div>
        <SimilarParts
          onNextPage={handleNavigateSimilarPartsToNextPage}
          onPreviousPage={handleNavigateSimilarPartsToPreviousPage}
          isAddingSimilarPartToCart={isLoadingAddPartToCart}
          similarPartsCollection={similarParts}
          selectedParts={selectedPartsToCompare}
          canSelectPart={isComparingParts}
          onAddSimilarPartToCart={handleAddSimilarPartToCart}
          onSelectSimilarPartToCompare={handleOnSelectPartToCompare}
        />
      </section>
    )
  }

  return (
    <div className="product-viewer-v2 vention-ui">
      <Breadcrumbs
        className="product-viewer-v2__header-breadcrumbs"
        type="extended"
        divider="chevron"
        breadcrumbs={buildBreadcrumbs()}
      />

      <header className="product-viewer-v2__header">
        <div className="product-viewer-v2__header-container">
          <ImageGallery product={part} />

          <div className="product-viewer-v2__header-details">
            <Header
              product={part}
              productVariantOptions={partVariantsOptions}
            />

            <div data-controller="accordion">
              <div className="open" data-accordion-target="entry">
                <button
                  data-action="click->accordion#toggle"
                  className="product-viewer-v2__accordion-title"
                >
                  <span className="product-viewer-v2__accordion-btn-chevron"></span>
                  Documentations
                </button>
                <div className="product-viewer-v2__accordion-content">
                  <TechnicalDocuments part={part} />
                </div>
              </div>
            </div>

            <div data-controller="accordion">
              <div className="open" data-accordion-target="entry">
                <button
                  data-action="click->accordion#toggle"
                  className="product-viewer-v2__accordion-title"
                >
                  <span className="product-viewer-v2__accordion-btn-chevron"></span>
                  Parts specifications
                </button>
                <div className="product-viewer-v2__accordion-content">
                  <Specifications part={part} />
                </div>
              </div>
            </div>

            {includedPartsAndFasteners.length > 0 && (
              <div data-controller="accordion">
                <div className="open" data-accordion-target="entry">
                  <button
                    data-action="click->accordion#toggle"
                    className="product-viewer-v2__accordion-title"
                  >
                    <span className="product-viewer-v2__accordion-btn-chevron"></span>
                    Included with part
                  </button>
                  <div className="product-viewer-v2__accordion-content">
                    <WhatsIncluded
                      includedPartsAndFasteners={includedPartsAndFasteners}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <Cart product={part} />
        </div>
      </header>

      <SimilarPartComponent />

      <CompareGadget
        isOpen={isComparingParts}
        onCleanCompare={handleOnCleanComparingParts}
        parts={[{ id: `${part.id}`, name: part.name, imagePath: part.image_path, partNumber: part.part_number } as Partial<PartDTO>, ...selectedPartsToCompare]}
        onCloseComparingParts={handleCloseComparingParts}
        onCompare={handleCompare}
        onSelectPart={handleOnSelectPartToCompare}
      />

      <Faqs />

      <CompareModal
        fullPartsToCompare={resutlFullCompareParts?.result?.data?.parts?.collection}
        isLoadingAddingPartToCart={isLoadingAddPartToCart}
        isOpen={isShowComparePartsModal}
        mainPart={{id: `${part.id}`, name: part.name, partNumber: part.part_number }}
        onAddPartToCart={handleAddSimilarPartToCart}
        onClose={handleCloseComparePartsModal}
        selectedPartsToCompare={selectedPartsToCompare}
        allPropertyLabels={allPropertyLabels}
      />
    </div>
  );
};

export default Page;