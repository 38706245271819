import { request } from 'graphql-request'
import { ErrorMetadata, PaginationMetadata } from '../../hooks/usePaginationGQL';
import { GRAPHQL_PUBLIC_API } from '../../constants/Vention';
import { SimilarPartDTO } from './dtos/SimilarPartDTO';
import { fetchSimilarPartsQuery } from './queries/SimilarPartQuery';
import { fetchFullPartsByIdsQuery } from './queries/FullPartQuery';
import { PartDTO } from './dtos/PartDTO';

export type PartCollectionDTO<T> = {
  parts: {
    collection: T[]
  } & PaginationMetadata & ErrorMetadata,
};

export const SimilarPartService = {
  querySimilarParts: async (page: number, perPage: number, partId?: number, categoryId?: number): Promise<PartCollectionDTO<SimilarPartDTO>> => {
    if (!partId || !categoryId) {
      return {
        parts: {
          collection: [],
          metadata: {
            currentPage: 0,
            limitValue: 0,
            totalCount: 0,
            totalPages: 0
          },
          errors: []
        },
      };
    }
    return request<PartCollectionDTO<SimilarPartDTO>>(
      GRAPHQL_PUBLIC_API,
      fetchSimilarPartsQuery,
      {
        excludePartId: [`${partId}`],
        categoryIds: [categoryId],
        page,
        pageSize: perPage
      }
    )
  },
  queryFullPartsByIds : async(page: number, perPage: number, includePartIds: (number | string)[]): Promise<PartCollectionDTO<PartDTO>> => {
    return request<PartCollectionDTO<PartDTO>>(
      GRAPHQL_PUBLIC_API,
      fetchFullPartsByIdsQuery,
      {
        page,
        pageSize: perPage,
        includePartIds
      }
    )
  }
}