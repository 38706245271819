import React from "react";
import { PartCollectionDTO } from "../../../../../api/Parts/PartsService";
import { Pagination } from "../../../../../components/simple_pagination/Pagination";
import Button from "../../../../../components/common/buttons/Button";
import { PartCardV2 } from "../../../../../components/parts/PartCardV2";
import { SimilarPartDTO } from "../../../../../api/Parts/dtos/SimilarPartDTO";
import { PartDTO } from "../../../../../api/Parts/dtos/PartDTO";

export interface SimilarPartsProps {
  similarPartsCollection?: PartCollectionDTO<SimilarPartDTO>;
  isAddingSimilarPartToCart: { [key: string]: boolean };
  selectedParts: SimilarPartDTO[];
  canSelectPart: boolean;
  onAddSimilarPartToCart: (partId: string) => void;
  onSelectSimilarPartToCompare: (part: Partial<PartDTO>) => void;
  onPreviousPage: () => void;
  onNextPage: () => void;
}

const SimilarParts: React.FC<SimilarPartsProps> = (props: SimilarPartsProps) => {
  return (
    <>
      <div className="product-viewer-v2__card-links">
        {props.similarPartsCollection?.parts.collection.map((part, index) => (
          <PartCardV2
            part={part}
            isSelected={props.selectedParts.includes(part)}
            isSelectable={props.canSelectPart}
            key={index}
            onSelectClicked={props.onSelectSimilarPartToCompare}
          >
            {
              <Button
                title="Add to Cart"
                className="vention-ui-button--secondary-gray-outline"
                isLoading={props.isAddingSimilarPartToCart?.[part.id] === true}
                onClick={() => {
                  props.onAddSimilarPartToCart(part.id);
                }}>
                {"Add to Cart"}
              </Button>
            }
          </PartCardV2>
        ))}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Pagination
          currentPage={props.similarPartsCollection?.parts?.metadata?.currentPage}
          lastPage={props.similarPartsCollection?.parts?.metadata?.totalPages}
          isLoading={false}
          moveToNext={props.onNextPage}
          moveToPrevious={props.onPreviousPage}
        />
      </div>
    </>
  );
};

export default SimilarParts;
