import React from 'react';
import Button from '../common/buttons/Button';
import { VRegularText } from '../text/Text';

export type PaginationProps = {
    currentPage?: number;
    lastPage?: number;
    moveToNext: () => void;
    moveToPrevious: () => void;
    isLoading: boolean;
}

export const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
    if (props.isLoading || !props.currentPage || !props.lastPage) {
        return null; // Instead of showing a broken component, do not show anything
    }


    return (
        <>
            <VRegularText>
                {'Page '}
                {props.currentPage.toString()}
                {' of '}
                {props.lastPage.toString()}
            </VRegularText>
            <Button className='button-blank space-padding-left-2xl space-padding-top-none space-padding-bottom-none' disabled={props.currentPage === 1} onClick={props.moveToPrevious}>
                {
                    <i className='ti ti-circle-arrow-left-filled icon-size-20px' />
                }
            </Button>
            <Button className='button-blank button-no-padding' disabled={props.currentPage === props.lastPage} onClick={props.moveToNext}>
                {
                    <div className='ti ti-circle-arrow-right-filled icon-size-20px'></div>
                }
            </Button>
        </>
    )
}