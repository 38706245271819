import React from "react";
import { Color, FontSize, FontType, Spacing, TextAlign } from "../types/types";

export interface VTextProps {
    children: string | string[];
    margin?: Spacing;
    marginTop?: Spacing;
    marginLeft?: Spacing;
    marginRight?: Spacing;
    marginBottom?: Spacing;
    padding?: Spacing;
    paddingTop?: Spacing;
    paddingLeft?: Spacing;
    paddingRight?: Spacing;
    paddingBottom?: Spacing;
    color?: Color;
    fontSize?: Spacing;
    fontType: FontType;
    align?: TextAlign;
    className? : string;
}

export const VText: React.FC<VTextProps> = (props: VTextProps) => {
    const { children } = props;
    const { margin, marginBottom, marginLeft, marginRight, marginTop } = props;
    const { padding, paddingBottom, paddingLeft, paddingRight, paddingTop } = props;
    let paddingClassName = getPaddingSpacingClassName({padding, paddingBottom, paddingLeft, paddingRight, paddingTop});
    let marginClassName = getMarginSpacingClassName({margin, marginBottom, marginLeft, marginRight, marginTop});
    let textFontClassName = `text-font-${props.fontType}`;
    let textFontSize = getFontSizeClassName(props?.fontSize);
    let textAlignClassName = getTextAlignClassName(props.align);
    let textColorClassName = getTextColorClassName(props?.color);
    return (<div style={{ whiteSpace: 'pre-wrap'}} className={`${textFontSize} ${textColorClassName} ${textAlignClassName} ${textFontClassName} ${paddingClassName} ${marginClassName} ${props.className ?? ''}`}>
        {
            children
        }
    </div>)
}

export type VBoldTextProps = Omit<VTextProps, 'fontType'>

export const VBoldText: React.FC<VBoldTextProps> = (props: VBoldTextProps) => {
    return <VText {...props} fontType={'Bold'} />
}

export const VSemiBoldText: React.FC<VBoldTextProps> = (props: VBoldTextProps) => {
    return <VText {...props} fontType={'SemiBold'} />
}

export const VExtraBoldText: React.FC<VBoldTextProps> = (props: VBoldTextProps) => {
    return <VText {...props} fontType={'ExtraBold'} />
}

export const VThinText: React.FC<VBoldTextProps> = (props: VBoldTextProps) => {
    return <VText {...props} fontType={'Thin'} />
}

export const VBlackText: React.FC<VBoldTextProps> = (props: VBoldTextProps) => {
    return <VText {...props} fontType={'Black'} />
}

export const VExtraLightText: React.FC<VBoldTextProps> = (props: VBoldTextProps) => {
    return <VText {...props} fontType={'ExtraLight'} />
}

export const VLightText: React.FC<VBoldTextProps> = (props: VBoldTextProps) => {
    return <VText {...props} fontType={'Light'} />
}

export const VMediumText: React.FC<VBoldTextProps> = (props: VBoldTextProps) => {
    return <VText {...props} fontType={'Medium'} />
}

export const VRegularText: React.FC<VBoldTextProps> = (props: VBoldTextProps) => {
    return <VText {...props} fontType={'Regular'} />
}

const getMarginSpacingClassName = (spacing: { margin?: Spacing, marginTop?: Spacing, marginBottom?: Spacing, marginLeft?: Spacing, marginRight?: Spacing}) => {
    if(spacing?.margin){
        return `space-margin-${spacing.margin}`;
    }
    const arraySpaces: string[] = [];
    if(spacing?.marginBottom){
        arraySpaces.push(`space-margin-bottom-${spacing.marginBottom}`);
    }
    if(spacing?.marginLeft){
        arraySpaces.push(`space-margin-left-${spacing.marginLeft}`);
    }
    if(spacing?.marginRight){
        arraySpaces.push(`space-margin-right-${spacing.marginRight}`);
    }
    if(spacing?.marginTop){
        arraySpaces.push(`space-margin-top-${spacing.marginTop}`);
    }
    return arraySpaces.join(" ");
}

const getPaddingSpacingClassName = (spacing: { padding?: Spacing, paddingTop?: Spacing, paddingBottom?: Spacing, paddingLeft?: Spacing, paddingRight?: Spacing}) => {
    if(spacing?.padding){
        return `space-padding-${spacing.padding}`;
    }
    const arraySpaces: string[] = [];
    if(spacing?.paddingBottom){
        arraySpaces.push(`space-padding-bottom-${spacing.paddingBottom}`);
    }
    if(spacing?.paddingLeft){
        arraySpaces.push(`space-padding-left-${spacing.paddingLeft}`);
    }
    if(spacing?.paddingRight){
        arraySpaces.push(`space-padding-right-${spacing.paddingRight}`);
    }
    if(spacing?.paddingTop){
        arraySpaces.push(`space-padding-top-${spacing.paddingTop}`);
    }
    return arraySpaces.join(" ");
}

const getTextAlignClassName = (align?: TextAlign) => {
    if(!align){
        return "";
    }
    
    return `text-align-${align}`;
}


const getTextColorClassName = (color?: Color) => {
    if(!color){
        return "";
    }

    return `text-color-${color}`;
}

const getFontSizeClassName = (fontSize?: FontSize) => {
    if(!fontSize){
        return "";
    }

    return `text-size-${fontSize}`;
}