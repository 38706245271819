import {
  AtomicBreadbox,
  AtomicDidYouMean,
  AtomicFacetManager,
  AtomicLayoutSection,
  AtomicLoadMoreResults,
  AtomicNoResults,
  AtomicQueryError,
  AtomicQuerySummary,
  AtomicRefineToggle,
  AtomicSearchBox,
  AtomicSearchInterface,
  AtomicSearchLayout,
  AtomicSortDropdown,
  AtomicSortExpression,
  buildSearchEngine,
  AtomicResultSectionVisual,
  AtomicResultImage,
  AtomicResultSectionTitle,
  AtomicResultLink,
  AtomicResultNumber,
  AtomicResultList,
  AtomicResultSectionExcerpt,
  AtomicCategoryFacet,
  Result,
  AtomicSearchBoxQuerySuggestions,
  AtomicResult,
  AtomicResultText,
  AtomicResultSectionTitleMetadata,
  AtomicResultSectionBottomMetadata,
  AtomicResultPrintableUri,
  ResultTemplateCondition,
} from "@coveo/atomic-react";
import React from "react";
import httpClient from "../../../../api/httpClient";
import I18n from "../../../../helpers/I18n";

const renderResult = (result: Result, props: PageProps): JSX.Element => {
  return (
    <>
      {/*
        This element is rendered from a template, and so its shadow DOM isn't accessible by global CSS. Sometimes these
        templates that Coveo uses have `part` attributes that can be used to target the shadow DOM elements, but not in
        this case.
      */}

      <style>{`
      atomic-result-section-title,
      atomic-result-section-excerpt,
      atomic-result-section-bottom-metadata {
        padding: 0 20px;
      }

      atomic-result-section-excerpt {
        max-height: 3em !important;
      }

      atomic-result-link a {
        text-decoration: none !important;
        color: var(--atomic-on-background) !important;
      }

      atomic-result-printable-uri a {
        color: var(--atomic-primary) !important;
      }

      atomic-result-text[field="source"] {
        display: block;
        color: var(--atomic-neutral-dark);
        font-size: 0.8rem;
      }
    `}</style>
      <AtomicResultSectionVisual>
        <AtomicResultLink>
          <AtomicResultImage
            field="ec_images"
            fallback="https://assets.vention.io/svg/icons/document-icon.svg"
          />
        </AtomicResultLink>
      </AtomicResultSectionVisual>
      <AtomicResultSectionTitle>
        <AtomicResultText field="source" />
        <AtomicResultLink />
      </AtomicResultSectionTitle>
      <AtomicResultSectionExcerpt>
        <AtomicResultLink>
          <AtomicResultText field="ec_description" />
        </AtomicResultLink>
      </AtomicResultSectionExcerpt>
      <AtomicResultSectionBottomMetadata>
        <AtomicResultPrintableUri />
      </AtomicResultSectionBottomMetadata>
    </>
  );
};

interface PageProps {
  accessToken: string;
  organizationId: string;
  organizationEndpoints: {
    platform: string;
    analytics: string;
    search: string;
  };
  locale: "en" | "fr";
  region: "us" | "eu" | "ca" | "intl";
  partCategoryNameByCode: Record<string, string>;
}

const Page: React.FC<PageProps> = (props) => {
  const engine = buildSearchEngine({
    configuration: {
      search: { pipeline: "Global Search" },
      accessToken: props.accessToken,
      organizationId: props.organizationId,
      organizationEndpoints: props.organizationEndpoints,
      renewAccessToken() {
        return httpClient({
          url: "/graphql",
          method: "post",
          data: JSON.stringify({
            query: "mutation { coveoJwtCreate(input: {}) { jwt } }",
          }),
        }).then((response) => response.data.data.coveoJwtCreate.jwt);
      },
    },
  });

  return (
    <AtomicSearchInterface
      localizationCompatibilityVersion="v4"
      languageAssetsPath="https://assets.vention.io/coveo-atomic-assets/lang"
      iconAssetsPath="https://assets.vention.io/coveo-atomic-assets/assets"
      engine={engine}
      language={props.locale}
    >
      <AtomicSearchLayout
        id="global-search-page"
        className="global-search-page"
      >
        <div className="atomic-header-section" style={{ gridArea: "header" }}>
          <h2>Global Search</h2>
        </div>

        <AtomicLayoutSection section="search">
          <AtomicSearchBox textarea={true}>
            <AtomicSearchBoxQuerySuggestions />
            <AtomicDidYouMean />
          </AtomicSearchBox>
        </AtomicLayoutSection>
        <AtomicLayoutSection section="facets">
          <AtomicFacetManager>
            <AtomicCategoryFacet field="source" label="Category" />
          </AtomicFacetManager>
        </AtomicLayoutSection>
        <AtomicLayoutSection section="main">
          <AtomicLayoutSection section="status">
            <AtomicBreadbox />
            <AtomicQuerySummary />
            <AtomicRefineToggle />
            <AtomicSortDropdown>
              <AtomicSortExpression label="relevance" expression="relevancy" />
              <AtomicSortExpression
                label="newest"
                expression="date descending"
              />
            </AtomicSortDropdown>
          </AtomicLayoutSection>
          <AtomicLayoutSection section="results">
            <AtomicResultList
              display="list"
              imageSize="small"
              template={(result) => ({
                contentTemplate: renderResult(result, props),
                linkTemplate: <></>,
              })}
            />
            <AtomicQueryError />
            <AtomicNoResults />
          </AtomicLayoutSection>
          <AtomicLayoutSection section="pagination">
            <AtomicLoadMoreResults />
          </AtomicLayoutSection>
        </AtomicLayoutSection>
      </AtomicSearchLayout>
    </AtomicSearchInterface>
  );
};

export default Page;
