import {
  AtomicBreadbox,
  AtomicDidYouMean,
  AtomicFacet,
  AtomicFacetManager,
  AtomicFormatCurrency,
  AtomicLayoutSection,
  AtomicLoadMoreResults,
  AtomicNoResults,
  AtomicNumericFacet,
  AtomicQueryError,
  AtomicQuerySummary,
  AtomicRefineToggle,
  AtomicSearchBox,
  AtomicSearchInterface,
  AtomicSearchLayout,
  AtomicSortDropdown,
  AtomicSortExpression,
  buildSearchEngine,
  AtomicSearchBoxRecentQueries,
  AtomicResultSectionVisual,
  AtomicResultImage,
  AtomicResultSectionTitle,
  AtomicResultLink,
  AtomicResultNumber,
  AtomicResultList,
  AtomicResultSectionExcerpt,
  AtomicCategoryFacet,
  AtomicIcon,
  Result,
  Raw,
} from "@coveo/atomic-react";
import React from "react";
import httpClient from "../../../../../api/httpClient";
import I18n from "../../../../../helpers/I18n";
import { transformKeys } from "../../../../../helpers/Objects";

interface PageProps {
  accessToken: string,
  organizationId: string,
  organizationEndpoints: { platform: string, analytics: string, search: string},
  locale: "en" | "fr",
  region: "us" | "eu" | "ca" | "intl",
  designCategoryNameLocalesByCode: { [key: string]: string }
}

interface DesignResultRaw extends Raw {
  likes_count: number,
  view_count: number
}

interface DesignResult extends Result {
  raw: DesignResultRaw
}

const isDesignResult = (result: Result): result is DesignResult => {
  return true
}

const facets = [
  { categoryCode: "SF", filters: ["safety_components", "safety_enclosure"] },
  { categoryCode: "CB", filters: ["robot_brand", "base"] },
  { categoryCode: "RC", filters: ["robot_brand", "base"] },
  { categoryCode: "PP", filters: ["robot_brand"] },
  { categoryCode: "SP", filters: ["robot_brand", "base_configuration", "number_of_stations"] },
  { categoryCode: "BE", filters: ["robot_brand"] },
  { categoryCode: "RB", filters: ["base", "height"] },
  { categoryCode: "JF", filters: ["base_plane", "accessories", "mounting"] },
  { categoryCode: "IR", filters: ["robot_payload"] },
  { categoryCode: "TBC", filters: ["size", "length"] },
  { categoryCode: "WS", filters: ["length", "width", "accessories"] },
  { categoryCode: "IC", filters: ["length", "width", "storage"] },
  { categoryCode: "EX", filters: ["range_extender_length", "actuator_type"] },
  { categoryCode: "PF", filters: ["number_of_axes"] },
  { categoryCode: "CV", filters: ["conveyor_type", "conveyor_width", "drive"] },
  { categoryCode: "SR", filters: ["storage"] },
  { categoryCode: "AM", filters: ["storage", "accessories"] }
]

const currencyCodeByRegion = {
  "us": "USD",
  "eu": "EUR",
  "ca": "CAD",
  "intl": "USD"
}

const currencySymbolByRegion = {
  "us": "$",
  "eu": "€",
  "ca": "$",
  "intl": "$"
}

const customIconsPath = "https://assets.vention.io/coveo-atomic-assets/custom_icons";

const renderResult = (result: Result, props: PageProps): JSX.Element => {
  if (isDesignResult(result)) {
    return <>
      {/*
        This element is rendered from a template, and so its shadow DOM isn't accessible by global CSS. Sometimes these
        templates that Coveo uses have `part` attributes that can be used to target the shadow DOM elements, but not in
        this case.
      */}

      <style>{`
        .stat-box-section {
          display: flex;
        }

        .stat-box {
          display: flex;
          align-items: center;
        }

        .stat-box:not(:last-child) {
          margin-right: 8px;
        }

        .stat-box svg {
          width: 14px;
          height: 14px;
        }

        atomic-result-section-visual {
          padding: 20px;
          background-color: #f8fafc;
        }

        atomic-result-image img {
          background-color: #ffffff;
          mix-blend-mode: multiply;
        }

        atomic-result-section-title {
          padding: 0 20px;
          min-height: 54px;
        }

        atomic-result-section-excerpt {
          padding: 0 20px 20px 20px;
        }

        atomic-result-link a:hover {
          text-decoration: none !important;
          color: var(--atomic-on-background) !important;
        }

        atomic-result-link a:visited {
          color: var(--atomic-on-background) !important;
        }
  
        // This removes the default max height on the excerpt section that causes it to be cut off on mobile
        @media not all and (min-width: 1024px) {
          .result-root.with-sections.display-grid.image-large.density-normal atomic-result-section-excerpt {
            max-height: unset !important;
          }
        }
      `}</style>
      <AtomicResultSectionVisual>
        <AtomicResultImage field="ec_images" />
      </AtomicResultSectionVisual>

      <AtomicResultSectionTitle>
        <AtomicResultLink />
      </AtomicResultSectionTitle>

      <AtomicResultSectionExcerpt>
        <div>
          <span>{currencySymbolByRegion[props.region]}</span>
          <AtomicResultNumber field="local_price" />
          <span> {currencyCodeByRegion[props.region]}</span>
        </div>
        <div className="stat-box-section">
          <div className="stat-box">
            <AtomicIcon icon={customIconsPath + "/thumbs_up.svg"} />
            <span>{result.raw.likes_count}</span>
          </div>
          <div className="stat-box">
            <AtomicIcon icon={customIconsPath + "/eye.svg"} />
            <span>{result.raw.view_count}</span>
          </div>
        </div>
      </AtomicResultSectionExcerpt>
    </>
  } else {
    return <></>
  }
}

const Page: React.FC<PageProps> = (props) => {
  const engine = buildSearchEngine({
    configuration: {
      search: { pipeline: "Vention Official Design Library" },
      accessToken: props.accessToken,
      organizationId: props.organizationId,
      organizationEndpoints: props.organizationEndpoints,
      renewAccessToken() {
        return httpClient({
          url: "/graphql",
          method: "post",
          data: JSON.stringify({
            query: "mutation { coveoJwtCreate(input: {}) { jwt } }"
          })
        }).then(response => response.data.data.coveoJwtCreate.jwt)
      },
      preprocessRequest: (request, _clientOrigin, metadata) => {
        if (metadata?.method === "search" && request.body) {
          const body = JSON.parse(request.body.toString());

          body.dictionaryFieldContext = {local_price: props.region}
          body.fieldsToInclude.push("local_price");
          body.fieldsToInclude.push("likes_count");
          body.fieldsToInclude.push("view_count");

          body.numberOfResults = 20;

          request.body = JSON.stringify(body);
        }

        return request;
      }
    }
  });

  return (
    <AtomicSearchInterface
      languageAssetsPath="https://ventionblobs.s3.us-east-1.amazonaws.com/coveo-atomic-assets/lang"
      iconAssetsPath="https://ventionblobs.s3.us-east-1.amazonaws.com/coveo-atomic-assets/assets"
      engine={engine}
      localization-compatibility-version="v4"
      language={props.locale}
      localization={(i18n) => {
        // Switching locales is done outside of React, so we can store all translations under 'en' since that's the 
        // default fallback locale
        const fallbackLocale = "en";
        
        const tagFacetsI18n = I18n.t("views.designs_library.official_designs_coveo.tag_facets");

        for (const facetName in tagFacetsI18n) {
          const tags = tagFacetsI18n[facetName].options;

          // Tags are kebab cased in Coveo (and our DB), but locale YAML files use snake case for keys
          const kebabCaseTags = transformKeys(tags, key => key.replace(new RegExp("_", 'g'), "-"));

          i18n.addResourceBundle(fallbackLocale, `caption-tags_${facetName}`, kebabCaseTags);
        }

        i18n.addResourceBundle(fallbackLocale, "translation", I18n.t("views.designs_library.official_designs_coveo.sort_by"));
        
        // Design category translations are stored in the 'translations' table of our DB
        i18n.addResourceBundle(fallbackLocale, "caption-ec_category", props.designCategoryNameLocalesByCode);

        i18n.addResourceBundle(
          fallbackLocale,
          "caption-filterable_design_traits",
          I18n.t("views.designs_library.official_designs_coveo.facets.filterable_design_traits.options")
        );
      }}
      >
      <AtomicSearchLayout>
        <div className="atomic-header-section" style={{gridArea: "header"}}>
          <h2>{I18n.t("views.designs_library.official_designs_coveo.header")}</h2>
        </div>
        <AtomicLayoutSection section="search">
          <AtomicSearchBox textarea={true}>
            <AtomicSearchBoxRecentQueries />
            <AtomicDidYouMean />
          </AtomicSearchBox>
        </AtomicLayoutSection>
        <AtomicLayoutSection section="facets">
          <AtomicFacetManager>
            <AtomicCategoryFacet field="ec_category" withSearch delimitingCharacter="|" label="Category"/>
          </AtomicFacetManager>

          <AtomicFacetManager>
            {
              facets.map((facet) => {
                return facet.filters.map((filter) => {
                  const id = `${filter}_${facet.categoryCode.toLowerCase()}`;

                  return <AtomicFacet
                    id={id}
                    key={id}
                    label={I18n.t(`views.designs_library.official_designs_coveo.tag_facets.${filter}.name`)}
                    field={`tags_${filter}`}
                    dependsOn={{ec_category: facet.categoryCode}}
                  />
                })
              })
            }
          </AtomicFacetManager>

          <AtomicFacetManager>
            <AtomicNumericFacet field="local_price" label="Price" withInput="integer" numberOfValues={0}>
              <AtomicFormatCurrency currency={currencyCodeByRegion[props.region]} />
            </AtomicNumericFacet>
          </AtomicFacetManager>

          <AtomicFacetManager>
            <AtomicFacet
              field="filterable_design_traits"
              label={I18n.t("views.designs_library.official_designs_coveo.facets.filterable_design_traits.name")}
              allowedValues={["ready_to_operate", "python_programmable", "code_free_programmable", "contain_motion_code"]}
            />
          </AtomicFacetManager>
        </AtomicLayoutSection>
        <AtomicLayoutSection section="main">
          <AtomicLayoutSection section="status">
            <AtomicBreadbox />
            <AtomicQuerySummary />
            <AtomicRefineToggle />
            <AtomicSortDropdown>
              <AtomicSortExpression label="relevance" expression="relevancy" />
              <AtomicSortExpression
                label="price_low_to_high"
                expression="local_price ascending"
              />
              <AtomicSortExpression
                label="price_high_to_low"
                expression="local_price descending"
              />
              <AtomicSortExpression
                label="most_popular"
                expression="popularity descending"
              />
              <AtomicSortExpression
                label="most_recent"
                expression="published_at descending"
              />
            </AtomicSortDropdown>
          </AtomicLayoutSection>
          <AtomicLayoutSection section="results">
            <AtomicResultList
              display="grid"
              imageSize="large"
              template={(result) => renderResult(result, props)}
            />
            <AtomicQueryError />
            <AtomicNoResults />
          </AtomicLayoutSection>
          <AtomicLayoutSection section="pagination">
            <AtomicLoadMoreResults />
          </AtomicLayoutSection>
        </AtomicLayoutSection>
      </AtomicSearchLayout>
    </AtomicSearchInterface>
  );
}

export default Page;
