import React, { useState } from "react";
import Button from "../../../../../components/vention_ui/shared_components/Button";
import QuantitySelector from "../../../../../components/vention_ui/shared_components/QuantitySelector";
import * as CartService from "../../../../../api/Cart/Service";
import { ShowPart } from "../Page";

const SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS = 8;

interface Props {
  readonly product: ShowPart;
}

export const getStatusColor = (statusClassName) => {
  switch (statusClassName) {
    case "warning":
      return "error";
    case "soon":
      return "warning";
    default:
      return "brand";
  }
};

export const Cart: React.FC<Props> = ({ product }) => {
  const [quantity, setQuantity] = useState<number | null>(null);
  const [addingToCart, setAddingToCart] = useState(false);

  const onQuantityChange = (quantity: number) => {
    setQuantity(quantity);
  };

  const onAddToCartClick = () => {
    if (!quantity) return;

    setAddingToCart(true);
    CartService.quickAddToCart(
      null,
      `/parts/${product.id}/buy?quantity=${quantity}`,
      "Part",
      () => setAddingToCart(false),
    );
  };

  const getLabelText = (statusClassName) => {
    switch (statusClassName) {
      case "unavailable":
        return "Unavailable";
      default:
        return "In Stock";
    }
  };

  return (
    <div className="product-viewer-v2__header-details-footer">
      {product.display_price_raw && (
        <p className="product-viewer-v2__header-price">
          <sup>{product.display_price_raw.currency_symbol}</sup>
          {product.display_price_raw.amount.toString().split(".")[0]}
          <sup>{`.${product.display_price_raw.amount.toString().split(".")[1]} ${product.display_price_raw.currency_code}`}</sup>
        </p>
      )}
      {product.shipment_delay_weeks <
        SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS && (
        <div className="product-viewer-v2__delivery-statuses">
          <label className="product-viewer-v2__delivery-time-disclaimer">
            Delivery time estimated
          </label>
          <strong className="product-viewer-v2__delivery-status-item">
            {product.status_availability.text}
          </strong>
          {product.is_light_duty && (
            <strong className="product-viewer-v2__delivery-status-item">
              Light duty part
            </strong>
          )}
        </div>
      )}

      <label
        className={`product-viewer-v2__availability-${product.status_availability.className}`}
      >
        {getLabelText(product.status_availability.className)}
      </label>
      {product.allow_checkout &&
      product.shipment_delay_weeks <
        SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS ? (
        <>
          <div className="product-viewer-v2__header-selectors">
            <div className="product-viewer-v2__header-quantity">
              <QuantitySelector
                onQuantityChange={onQuantityChange}
                name="quantity"
                label="Quantity"
                size="xl"
              />
            </div>
          </div>
          <Button
            className="product-viewer-v2__header-cta"
            heirarchy="primary"
            size="2xl"
            iconLeading="ti ti-shopping-cart"
            onClick={onAddToCartClick}
            disabled={addingToCart}
          >
            {addingToCart ? "Adding..." : "Add to cart"}
          </Button>
        </>
      ) : (
        <Button
          className="product-viewer-v2__header-cta"
          heirarchy="primary"
          size="2xl"
          iconLeading="ti ti-messages"
          href="/contact"
        >
          Contact Us
        </Button>
      )}

      <hr />
      <div className="product-viewer-v2__cart-footer">
        <a href="/contact/new?choice=technical+support" target="_blank">
          <img src="https://assets.vention.io/svg/icons/Headset.svg" /> Talk
          with an Expert
        </a>
      </div>
    </div>
  );
};
